import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectCampaign } from "../../../redux/campaign/campaign.selectors"
import {
  selectAccounts,
  selectAccountId,
} from "../../../redux/account/account.selectors"
import { saveCampaign } from "../../../redux/campaign/campaign.actions"
import { database } from "../../../firebase"
import SpinnerComponent from "../../commons/SpinnerComponent"
import NoSuchDocument from "../../commons/NoSuchDocument"
import CampaignTagsRedux from "../edit-campaign/components/CampaignTagsRedux"
import ReelerButton from "../../commons/ReelerButton"
import FadeAlert from "../../commons/FadeAlert/FadeAlert"
import DeleteCampagin from "../components/DeleteCampaign"

import { CircleContainer, Circle, VerticalLine } from "./WhatsApp.styles"
import Section from "../../../layouts/core/Section"
import { Header } from "../../../layouts/core/Header"
import Title from "../../../layouts/core/Title"
import Body from "../../../layouts/core/Body"
import ShowCampaingAssets from "../components/ShowCampaingAssets"

import { MAIN_ROUTES } from "../../../constants/routes"
import useUserFeedbackMessage from "../../../hooks/useUserFeedbackMessage"
import { set } from "lodash"
import { FaCheck } from "react-icons/fa"

/**
 * Instagram Mentions webhook
 * https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow#step-4--subscribing-for-webhook-notifications
 *
 */

export default function EditWhatsAppCampaignView() {
  const dispatch = useDispatch()
  const { setSuccessMessage, setErrorMessage } = useUserFeedbackMessage()
  const campaign = useSelector(selectCampaign)
  const account = useSelector(selectAccounts)
  const accountId = useSelector(selectAccountId)
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [users, setUsers] = useState(null)
  const [msg, setMsg] = useState()

  useEffect(() => {
    setLoading(true)
    if (campaign && account) {
      setLoading(false)
    }
  }, [account, campaign])

  const enableWhatsAppSubscription = async (waba_id, accessToken) => {
    try {
      const { data } = await axios({
        url: `https://graph.facebook.com/v20.0/${waba_id}/subscribed_apps`,
        method: "post",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      if (data) return data
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (accountId) {
      database.users
        .where("accounts", "array-contains", accountId)
        .get()
        .then(querySnapshot => {
          let userArray = []
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            userArray.push(database.formatDoc(doc))
          })
          setUsers(userArray)
        })
    }
  }, [accountId])

  const handleSubscribe = async () => {
    setMsg(null)
    setIsSubscribing(true)

    //Enable page subscription
    const subscriptionResponse = await enableWhatsAppSubscription(
      account.integrations.whatsapp.waba_id,
      account.integrations.whatsapp.system_user_access_token
    )

    // Check if page subscription is enabled, otherwise abort
    if (!subscriptionResponse?.success) {
      setErrorMessage("Error: Cannot subscribe to whats app business account")

      setIsSubscribing(false)
      return
    }

    // Update campaign
    let { id: value, ...campaignWithoutId } = campaign

    let newData = {
      isSubscribed: true,
      waba_id: account.integrations.whatsapp.waba_id,
      phone_number_id: account.integrations.whatsapp.phone_number.id,
      tags: { whatsapp: true },
    }
    database.campaigns
      .doc(campaign.id)
      .set(
        {
          ...campaignWithoutId,
          ...newData,
        },
        { merge: true }
      )
      .then(() => {
        dispatch(
          saveCampaign({
            ...campaign,
            ...newData,
          })
        )
        setSuccessMessage("Content source updated successfully!")
        setIsSubscribing(false)
      })
  }
  const handleSubmit = async () => {
    setMsg(null)
    setSaving(true)

    if (!campaign.campaignName) {
      setErrorMessage("Please fill in a content source name")

      setSaving(false)
      return
    }

    // Update campaign
    let { id: value, ...campaignWithoutId } = campaign

    database.campaigns
      .doc(campaign.id)
      .set(
        {
          ...campaignWithoutId,
        },
        { merge: true }
      )
      .then(() => {
        dispatch(
          saveCampaign({
            ...campaign,
          })
        )
        setSuccessMessage("Content source updated successfully!")

        setSaving(false)
      })
  }

  // Generate a QR code for the WhatsApp number
  // https://developers.facebook.com/docs/whatsapp/business-management-api/qr-codes/

  if (loading) {
    return (
      <Container>
        <SpinnerComponent size="lg" />
      </Container>
    )
  }

  if (account && !campaign) {
    return (
      <NoSuchDocument
        message="Could not find the campaign."
        img="../../no_content_women_dog.svg"
        alt=""
      />
    )
  }

  return (
    <Section>
      <Header>
        <Title>
          Edit {campaign?.campaignName ? campaign.campaignName : null}
        </Title>
        <div className="d-flex flex-row ml-auto">
          <div className="mr-2">
            <ReelerButton
              text="All sources"
              styleClass="btn-secondary"
              dispatch={() => history.push(MAIN_ROUTES.campaigns.path)}
            />
          </div>
          <div className="mr-2">
            <ShowCampaingAssets />
          </div>
          <ReelerButton
            text="Save"
            styleClass="btn-main"
            loading={saving}
            dispatch={() => handleSubmit()}
          />
        </div>
      </Header>
      <Body>
        {account && !campaign ? (
          <NoSuchDocument
            message="Could not find the campaign."
            img="../../no_content_women_dog.svg"
            alt=""
          />
        ) : account?.integrations?.whatsapp?.waba_id &&
          account?.integrations?.whatsapp?.system_user_access_token ? (
          <>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign.campaignName ? true : false}>
                    1
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <Form.Group controlId="campaign-name">
                  <Row className="p-3 mb-3">
                    <Col sm={4} className="d-flex">
                      <Form.Label className="mb-0">
                        Content source name
                      </Form.Label>
                    </Col>
                    <Col sm={8} className="d-flex">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={
                            campaign.campaignName ? campaign.campaignName : ""
                          }
                          onChange={e => {
                            dispatch(
                              saveCampaign({
                                ...campaign,
                                campaignName: e.target.value,
                              })
                            )
                          }}
                          required
                        />
                        <Form.Text muted>For internal use only</Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign?.isSubscribed}>2</Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <Form.Group controlId="search-hashtag">
                  <Row className="p-3 mb-3">
                    <Col sm={4}>
                      <Form.Label className="mb-0">
                        Subscibe source to WhatsApp
                      </Form.Label>
                    </Col>
                    <Col sm={8}>
                      {campaign?.isSubscribed ? (
                        <div>
                          <div className="d-flex flex-row align-items-center">
                            <FaCheck
                              style={{ color: "var(--reeler)" }}
                              className="mr-2"
                            />
                            <span className="mr-3">Connected</span>
                            <span>
                              {
                                account?.integrations?.whatsapp?.phone_number
                                  ?.display_phone_number
                              }
                            </span>
                          </div>
                          <Form.Text muted>
                            Invite your end-users to send messages with photos
                            or videos to the connected WhatsApp number. Photos,
                            videos, and their captions will be imported into
                            Reeler, while text-only messages will be ignored.
                            Please note that WhatsApp must not be installed on
                            any phone using the same number as the connected
                            WhatsApp account.
                          </Form.Text>
                        </div>
                      ) : (
                        <Form.Group>
                          <ReelerButton
                            loading={isSubscribing}
                            disabled={isSubscribing}
                            text="Activate WhatsApp content source"
                            dispatch={() => handleSubscribe()}
                          />

                          <Form.Text muted>
                            Since you’ve already connected Reeler to your
                            WhatsApp Business Account, simply press the button
                            to activate content sourcing through that WhatsApp
                            number.
                          </Form.Text>
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign.confirmationMessage ? true : false}>
                    3
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <Form.Group controlId="campaign-name">
                  <Row className="p-3 mb-3">
                    <Col sm={4} className="d-flex">
                      <Form.Label className="mb-0">
                        Submission confirmation message
                      </Form.Label>
                    </Col>
                    <Col sm={8} className="d-flex">
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Ex. Thank you for your content submission. It has been received."
                          defaultValue={
                            campaign.confirmationMessage
                              ? campaign.confirmationMessage
                              : ""
                          }
                          onBlur={e => {
                            dispatch(
                              saveCampaign({
                                ...campaign,
                                confirmationMessage: e.target.value,
                              })
                            )
                          }}
                          required
                        />
                        <Form.Text muted>
                          Write a confirmation message to be sent automatically
                          after content is submitted. Be sure to include your
                          company name and a link to your UGC terms & conditions
                          (just type the URL starting with https://www...).
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign.confirmationMessage ? true : false}>
                    4
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <Form.Group controlId="campaign-name">
                  <Row className="p-3 mb-3">
                    <Col sm={4} className="d-flex">
                      <Form.Label className="mb-0">
                        Content requirement message
                      </Form.Label>
                    </Col>
                    <Col sm={8} className="d-flex">
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Ex. This WhatsApp number only accepts messages with a photo or video. Text-only messages are not accepted."
                          defaultValue={
                            campaign.contentRequirementMessage
                              ? campaign.contentRequirementMessage
                              : ""
                          }
                          onBlur={e => {
                            dispatch(
                              saveCampaign({
                                ...campaign,
                                contentRequirementMessage: e.target.value,
                              })
                            )
                          }}
                          required
                        />
                        <Form.Text muted>
                          Please note that you will not receive any text-only
                          messages. Write a content requirement error message
                          that will be automatically sent to the user if they
                          submit a message with only text and no media content.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle
                    active={
                      campaign?.tags && Object.keys(campaign?.tags).length > 0
                        ? true
                        : false
                    }
                  >
                    5
                  </Circle>
                </CircleContainer>
                <VerticalLine />
              </Col>
              <Col sm={9} md={10}>
                <CampaignTagsRedux campaign={campaign} />
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={2} className="d-flex flex-column">
                <CircleContainer>
                  <Circle active={campaign?.userId ? true : false}>6</Circle>
                </CircleContainer>
              </Col>
              <Col sm={9} md={10}>
                <Row className="p-3 mb-3">
                  <Col sm={4}>
                    <Form.Label className="mb-0">Set source owner</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Group controlId="userId">
                      <Form.Control
                        as="select"
                        name="userId"
                        value={campaign?.userId}
                        onChange={e =>
                          dispatch(
                            saveCampaign({
                              ...campaign,
                              userId: e.target.value,
                            })
                          )
                        }
                      >
                        {users?.map((user, key) => (
                          <option key={key} value={user.id}>
                            {user.email}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Text muted>
                        Set “Source Owner” to be able to easily find your
                        content, if you are many people or many teams working in
                        the same Reeler account.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div>
              {msg && <FadeAlert msg={msg} delay={5000} />}
              <div className="d-flex flex-row justify-content-end py-3 mb-5">
                <div className="mr-2">
                  <DeleteCampagin />
                </div>
                <ReelerButton
                  text="Save"
                  styleClass="btn-main"
                  loading={saving}
                  dispatch={() => handleSubmit()}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center pt-5">
            <img
              className="mb-3"
              height="150px"
              src="../../women_working_out.svg"
              alt=""
            />
            <p className="p-3">
              You need to connect your WhatsApp Business account to Reeler to be
              able connect this source to your WhatsApp Business account
            </p>

            <Button
              as={Link}
              className="btn-main"
              to={MAIN_ROUTES.settingsIntegration.path}
            >
              Connect your WhatsApp business account here
            </Button>
            <p className="p-3">
              Or cancel for now by deleting this new WhatsApp source.
            </p>
            <DeleteCampagin />
          </div>
        )}
      </Body>
    </Section>
  )
}
